<template>
  <div class="lightbox-wrapper" v-if="shown">
    <button class="close-btn" type="button" @click="hide">x</button>
    <button class="prev-btn" type="button" @click="prev" v-if="hasPrev">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" fill="currentColor" /></svg>
    </button>
    <button class="next-btn" type="button" @click="next" v-if="hasNext">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" fill="currentColor" /></svg>
    </button>
    <div class="lightbox">
      <div class="images-wrapper" ref="imagesWrapper" @scroll="handleScroll">
        <PinchZoom limit-zoom="99999" v-for="(src) in srcs" :key="src">
          <img :src="src">
        </PinchZoom>
      </div>
    </div>
  </div>
</template>

<script>
  import PinchZoom from 'vue-pinch-zoom'

  export default {
    components: { PinchZoom },
    data: () => ({
      srcs: [],
      index: 0,
      shown: false,
      debounceTimer: null,
    }),
    computed: {
      hasPrev() {
        return this.index > 0
      },
      hasNext() {
        return this.index < this.srcs.length - 1
      },
    },
    methods: {
      show(srcs, index) {
        document.addEventListener('keydown', this.hideWhenEscKeyBePressed)

        this.srcs = srcs
        this.index = index
        this.shown = true
        this.$nextTick(() => this.scroll())
      },
      hide() {
        this.shown = false

        document.removeEventListener('keydown', this.hideWhenEscKeyBePressed)
      },
      next() {
        if (!this.hasNext) {
          return
        }

        this.index += 1
        this.scroll()
      },
      prev() {
        if (!this.hasPrev) {
          return
        }

        this.index -= 1
        this.scroll()
      },
      hideWhenEscKeyBePressed({ key }) {
        if (key === 'Escape') {
          this.hide()
        }

        if (key === 'ArrowRight') {
          this.next()
        }

        if (key === 'ArrowLeft') {
          this.prev()
        }
      },
      scroll() {
        this.$refs.imagesWrapper.scrollLeft = this.index * this.$refs.imagesWrapper.clientWidth
      },
      handleScroll() {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer)
        }

        this.debounceTimer = setTimeout(() => {
          this.index = Math.round(this.$refs.imagesWrapper.scrollLeft / this.$refs.imagesWrapper.clientWidth)
        }, 200)
      },
    },
  }
</script>

<style scoped>
  .lightbox-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background: #000;
  }

  .close-btn,
  .prev-btn,
  .next-btn {
    position: absolute;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    background: transparent;
    border: none;
    padding: 0;
    line-height: 1;
    z-index: 10;
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.4));
  }

  .close-btn {
    top: 1rem;
    right: 1rem;
  }

  .prev-btn,
  .next-btn {
    top: 50%;
    transform: translateY(-50%);

    svg {
      transform: scale(0.75);
    }
  }

  .prev-btn {
    left: 1rem;
  }

  .next-btn {
    right: 1rem;
  }

  .lightbox, .pinch-zoom-wrapper {
    width: 100%;
    height: 100%;
  }

  .images-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .pinch-zoom-wrapper {
    flex-shrink: 0;
    scroll-snap-align: start;
  }
</style>
