var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sticker-panel"},[_c('header',{staticClass:"sticker-panel-header"},[_c('ul',{staticClass:"package-list"},_vm._l((_vm.stickerPackages),function(ref){
var id = ref.id;
var cover = ref.cover;
return _c('li',{key:id,staticClass:"package-cover",class:{ active: _vm.selectedPackage === id }},[_c('button',{on:{"click":function($event){$event.preventDefault();_vm.selectedPackage = id}}},[_c('img',{attrs:{"src":cover}})])])}),0),_c('button',{staticClass:"close-button",on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_vm._v("x")])]),_c('main',[_c('ul',{staticClass:"sticker-list"},[(!_vm.selectedStickers)?_c('li',{staticClass:"sticker-loading"},[_c('b-spinner',{attrs:{"variant":"secondary"}})],1):_vm._l((_vm.selectedStickers),function(ref){
var id = ref.id;
var sticker_package_id = ref.sticker_package_id;
var url = ref.url;
return _c('li',{key:id,staticClass:"sticker-image"},[_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.$emit('sticker-selected', { stickerPackageId: sticker_package_id, stickerId: id })}}},[_c('img',{attrs:{"src":url}})])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }