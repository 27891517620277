<template>
  <div class="sticker-panel">
    <header class="sticker-panel-header">
      <ul class="package-list">
        <li
          v-for="{ id, cover } in stickerPackages"
          :key="id"
          class="package-cover"
          :class="{ active: selectedPackage === id }"
        >
          <button @click.prevent="selectedPackage = id">
            <img :src="cover" />
          </button>
        </li>
      </ul>
      <button class="close-button" @click.prevent="$emit('close')">x</button>
    </header>
    <main>
      <ul class="sticker-list">
        <li v-if="!selectedStickers" class="sticker-loading">
          <b-spinner variant="secondary" />
        </li>
        <template v-else>
          <li
            v-for="{ id, sticker_package_id, url } in selectedStickers"
            :key="id"
            class="sticker-image"
          >
            <button @click.prevent="$emit('sticker-selected', { stickerPackageId: sticker_package_id, stickerId: id })">
              <img :src="url" />
            </button>
          </li>
        </template>
      </ul>
    </main>
  </div>
</template>

<script>
  export default {
    props: {
      stickerPackages: {
        type: Array,
        default: () => []
      },
      stickers: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      selectedPackage: null,
    }),
    watch: {
      stickerPackages(stickerPackages) {
        if (this.selectedPackage) {
          return
        }

        this.selectedPackage = stickerPackages[0].id;
      },
      selectedPackage(id) {
        this.$emit('package-selected', id);
      },
    },
    computed: {
      selectedStickers() {
        return this.stickers[this.selectedPackage];
      }
    },
    mounted() {
      if (this.stickerPackages.length) {
        this.selectedPackage = this.stickerPackages[0].id;
      }
    },
  }
</script>

<style scoped>
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .sticker-panel-header {
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.125rem;
  }

  .package-list {
    background-color: #eee;
  }

  .package-cover {
    width: 3rem;
    height: 3rem;
    padding: 0.25rem;
  }

  .package-cover.active {
    background-color: #ddd;
  }

  .sticker-list {
    overflow-y: auto;
    height: 11rem;
  }

  .sticker-image {
    width: 5rem;
    height: 5rem;
    padding: 0.5rem;
  }

  .sticker-image button:hover img {
    transform: scale(1.1);
  }

  .sticker-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
